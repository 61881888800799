<template>
	<div>
		<div class="mode-center">
			<div
				class="anime-hover  text-pine  grid grid-cols-5 gap-24 mx900:gap-0 pt-170 pb-16 mx900:grid-cols-1 w-full mx640:pt-140"
			>
				<div class="col-span-3">
					<h1
						v-if="data_fundmanagement_intro.title"
						class="h1-text mb-8 mx640:text-9 mx900:text-6 mx1440:text-4.5"
					>
						{{ data_fundmanagement_intro.title }}
					</h1>
					<div
						v-if="data_fundmanagement_intro.text"
						v-html="data_fundmanagement_intro.text"
						class="font-tiempos_r section_text section_text_bread anime-hover rich-text"
					></div>
				</div>
				<div class="col-span-2" v-if="data_fundmanagement_intro.image">
					<img class="w-full" :src="data_fundmanagement_intro.image.md" />
				</div>
				<div class="col-span-2" v-if="data_fundmanagement_intro.image == null">
					<img
						class="mt-120 mx900:mt-0 mx640:w-1/2 mx900:w-9/12 mx640:mx-auto"
						src="@/assets/logo-huge.svg"
						alt=""
					/>
				</div>
			</div>
		</div>
		<div v-if="data_investment_steps" class="bg-verbena">
			<div class="mode-center text-pine pt-140 pb-40">
				<div
					v-if="data_investment_steps.list"
					class="pb-24 h1-text mx640:text-9"
				>
					{{ data_investment_steps.title }}
				</div>
				<Step
					v-for="step in data_investment_steps.list"
					:key="step.id"
					:step="step"
					:isInvestment="true"
				/>
			</div>
		</div>
		<div class=" bg-mandarin sun-funds-up" v-if="data_want_more.content">
			<div
				class="mode-center text-pine text-40 py-16 font-tiempos_r mx900:text-4 mx640:text-6 anime-hover-white"
				v-html="data_want_more.content"
			></div>
		</div>
		<div class="sun-funds-down" v-if="data_grow_your_impact">
			<div class="mode-center">
				<div
					class="w-11/12 text-pine grid grid-cols-8 gap-8 pt-230 pb-85 mx640:grid-cols-1 items-baseline"
				>
					<h1
						v-if="data_grow_your_impact.title"
						class="h1-text col-span-3 mx640:text-9"
					>
						{{ data_grow_your_impact.title }}
					</h1>
					<div
						v-if="data_grow_your_impact.text"
						v-html="data_grow_your_impact.text"
						class="font-tiempos_r col-span-4 col-start-5 section_text section_text_bread anime-hover mx640:col-start-1 rich-text"
					></div>
				</div>
			</div>
		</div>
		<div class="bg-pine">
			<div
				class="pt-120 pb-100 ml-5% grid grid-cols-5 gap-10 mx640:grid-cols-1 w-105% mx900:grid-cols-4 mx900:w-90%"
			>
				<div class=" text-white max-w-510 col-span-2">
					<div
						class="h-full mx640:mt-0 flex flex-col justify-between min-h-520 mx640:min-h-0"
					>
						<div class="">
							<div
								class="text-40 font-national mx1080:text-4 mx640:text-6 pb-8"
							>
								{{ fundData.title }}
							</div>
							<div
								class="text-24 font-tiempos_r leading-9 mx1080:text-2 mx640:text-4 pb-8"
							>
								{{ fundData.description }}
							</div>
						</div>
						<div class="mx1080:text-2 mx640:text-4" v-if="fundData.specs">
							<div class="border-b border-crab"></div>
							<div
								v-for="(item, index) in fundData.specs"
								:key="index"
								class="border-b border-crab py-4"
							>
								{{ item.spec }}
							</div>
						</div>
					</div>
				</div>
				<FundCarousel
					class="col-span-3 mx900:col-span-2"
					:slides="carouselData"
					@slideClick="handleSlideClick"
				/>
			</div>
		</div>
		<GetInTouch />
	</div>
</template>

<script>
	import { EventBus } from "@/modules/event_bus";
	import { globals } from "@/modules/globals";
	import Step from "@/components/ui/Step.vue";
	import GetInTouch from "@/components/ui/GetInTouch.vue";
	import FundCarousel from "@/components/ui/FundCarousel.vue";
	import data from "@/data.json";
	export default {
		name: "Funds",
		components: {
			Step,
			GetInTouch,
			FundCarousel,
		},
		data() {
			return {
				tempData: data,
				selectedCarousel: 0,
				carouselData: null,
				fundData: {},
				data_fundmanagement_intro: {},
				data_grow_your_impact: {},
				data_investment_steps: {},
				data_want_more: globals.segments["quick-scan"],
				txt_capital: globals.microcopy.txt_capital[globals.language],
				txt_phase: globals.microcopy.txt_phase[globals.language],
			};
		},
		beforeRouteEnter(to, from, next) {
			let dataFiles = 2;
			let fundCarouselData = null;
			let json_file_fundCarousel =
				"/json/funds/funds_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_fundCarousel)
				.then((response) => response.json())
				.then(function(data) {
					fundCarouselData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});
			let sectionsData = null;
			let json_file =
				"/json/sections/sections_fund-management_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file)
				.then((response) => response.json())
				.then(function(data) {
					sectionsData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});
			let countLoaded = 0;
			function afterJsonLoaded() {
				countLoaded++;
				if (countLoaded == dataFiles) {
					next((vm) => vm.setData(sectionsData, fundCarouselData));
				}
			}
		},
		mounted() {},
		methods: {
			handleSlideClick(currentSlide) {
				this.selectedCarousel = currentSlide;
				this.getFundData();
			},
			setData(sectionsData, fundCarouselData) {
				fundCarouselData.forEach(function(item, index) {
					item.specs = JSON.parse(item.specs);
				});
				//console.log(fundCarouselData)

				this.setSectionsData(sectionsData);
				this.setCarouselData(fundCarouselData);
			},
			setCarouselData(data) {
				this.carouselData = data;
				this.getFundData();
			},
			setSectionsData(data) {
				this.data_fundmanagement_intro = data["fundmanagement-intro"];
				this.data_grow_your_impact = data["grow-your-impact"];
				this.data_investment_steps = data["investment-steps"];
			},
			getFundData() {
				this.fundData = this.carouselData[this.selectedCarousel];
			},
		},
	};
</script>

<style>
	/*
	.sun-funds-up{
		background-image: url('../assets/sun/sun-funds-up.svg');
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 100vw;
	}
	.sun-funds-down{
		background: url('../assets/sun/sun-funds-down.svg');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 100vw;
	}
	*/

	.sun-funds-up {
		background-image: url("../assets/sun/sun-funds-up.svg");
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: 100vw;
		background-attachment: fixed;
	}
	.sun-funds-down {
		background: url("../assets/sun/sun-funds-up.svg");
		background-repeat: no-repeat;
		background-position: 70% 100%;
		background-size: 100vw;
		background-attachment: fixed;
	}
</style>
